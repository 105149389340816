<script setup lang="ts">
import { Logger } from 'utils';

defineProps<{
  blok: {
    description?: string | null;
    title?: string | null;
  };
}>();

const translationsKeys = {
  placeholder: 'footer.newsletter.placeholder',
  submit: 'footer.newsletter.submit',
};

const { locale } = useI18n();
const { BASE_URL } = useBaseUrl();
const { getToken } = useReCaptcha();

const email = ref('');
const formRef = ref<HTMLFormElement | null>(null);
const token = ref<string | null>(null);

const signUp = async () => {
  try {
    token.value = await getToken('newsletter');
  } catch (error) {
    Logger.error('Newsletter recaptcha failed', error);
  }

  if (!token.value) {
    Logger.error('No newsletter recaptcha token');
  }

  if (formRef.value) {
    nextTick(() => formRef.value?.submit());
  } else {
    Logger.error('Newsletter form reference is null');
  }
};
</script>

<template>
  <div
    v-editable="blok"
    class="bg-purple-extra-light py-12"
  >
    <div class="container max-w-7xl">
      <div class="rounded-2xl">
        <div class="grid gap-8 md:grid-cols-2 md:gap-12">
          <div class="text-purple">
            <h3 class="mb-4 mt-0 text-4xl">
              <FormattedMarkdown
                v-if="blok.title"
                allow-text-formatting
                :content="blok.title"
              />
            </h3>
            <div class="opacity-80">
              <FormattedMarkdown
                v-if="blok.description"
                allow-text-formatting
                :content="blok.description"
                :inline="false"
              />
            </div>
          </div>
          <div class="flex items-center justify-center">
            <form
              ref="formRef"
              accept-charset="utf-8"
              class="flex w-full flex-col gap-2"
              method="post"
              :action="`${BASE_URL}/${locale}/newsletter/subscribe`"
              @submit.prevent="signUp"
            >
              <input
                v-model="email"
                required
                autocomplete="email"
                class="focus-visible::outline-none h-14 w-full min-w-60 rounded-lg border-2 border-grey-mid bg-white px-4 py-3 text-dark focus-visible:border-purple-dark focus-visible:ring-purple-dark"
                name="email"
                type="email"
                :placeholder="$t(translationsKeys.placeholder)"
              />
              <input
                name="reCaptchaToken"
                type="hidden"
                :value="token"
              />
              <Button
                class="text-base"
                size="large"
                type="submit"
                variant="cta"
              >
                {{ $t(translationsKeys.submit) }}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
