import {
  load,
  ReCaptchaInstance,
} from 'recaptcha-v3';
import { State } from '@/config/state';

export const useReCaptcha = () => {
  const reCaptcha = useState<ReCaptchaInstance | undefined>(State.Recaptcha, () => undefined);

  const { public: { reCaptchaKey } } = useRuntimeConfig();

  const getToken = async (action: string) => {
    if (!reCaptcha.value) {
      reCaptcha.value = await load(reCaptchaKey as string);
    }

    return reCaptcha.value.execute(action);
  };

  return { getToken };
};
